import { AppContext } from "@contextProviders/AppContextProvider";
import { useContext } from "react";

const ORDER_INFO = "order_info";

const storage = {
    get: (key) => {
        return window.localStorage.getItem(key);
    },
    set: (key, val) => {
        return window.localStorage.setItem(key, val);
    },
    remove: (key) => {
        return window.localStorage.removeItem(key);
    },
};

const setOrderInfo = (token) => {
    storage.set(ORDER_INFO, token);
};

const getOrderInfo = () => {
    return storage.get(ORDER_INFO);
};

const removeOrderInfo = () => {
    storage.remove(ORDER_INFO);
};

const useExpireCartIfTimeElapsed = () => {
    const { removeCart } = useContext(AppContext);
    const hours = 2;
    const now = new Date().getTime();
    const setupTime = localStorage.getItem("setupTime");
    if (setupTime == null) {
        localStorage.setItem("setupTime", now);
    } else if (now - setupTime > hours * 60 * 60 * 1000) {
        removeCart();
        localStorage.setItem("setupTime", now);
    }
};

export { setOrderInfo, getOrderInfo, removeOrderInfo, useExpireCartIfTimeElapsed };
